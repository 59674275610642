import React from "react"

export const CustomButton = ({ setData, Data, DataValue }) => {
  return (
    <button className="border-0 bg-transparent" style={{margin:"3px 0"}}
      onClick={() => {
          setData(DataValue)
      }}
    >
      <div className={Data == DataValue ? "active-option" : "passive-option"}>
        <b >{DataValue}</b>
      </div>
    </button>
  )
}