import React, { useEffect, useState } from 'react';
import Resizer from "react-image-file-resizer";
import { color1, color2, color3, Headphone } from '../../../Constant/api';
const AnswerOptionsSelect = ({
  AnswerArray,
  setData, Data,
  DataValue,
  option,
  ArrayIndex,
  QuestionsSolved, setQuestionsSolved,
  index,
  correct,
  setTotalCorrect, image

}) => {
  return (
    <div className="answer-option-style"
      style={{ display: 'flex', }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}
        onClick={() => {
          setData(option)
          // if (AnswerArray[ArrayIndex].answerChoosen == "") {
          //   setQuestionsSolved(QuestionsSolved + 1)
          // }
          const ArrayJSONvalue = AnswerArray[ArrayIndex]
          AnswerArray[ArrayIndex] = {
            image: image,
            number: DataValue,
            correct_answer: correct,
            answerChoosen: option
          }
        }}
      >
        {Data == option ?
          <div style={{
            border: "4px solid #000",
            borderRadius: "20px",
            width: "30px",
            height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
            fontSize: "16px", fontWeight: "bold", color: "#fff", backgroundColor: "#000"
          }}>
            &nbsp;{index}&nbsp;
          </div>
          :
          <div style={{
            border: "4px solid black",
            borderRadius: "20px",
            width: "30px",
            height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
            fontSize: "16px", fontWeight: "bold"
          }}>
            &nbsp;{index}&nbsp;
          </div>
        }
        {/* {Data == option ?
          <i className="fa fa-check-circle-o" style={{ fontSize: 25, color: "green" }}></i>
          : <i className="fa fa-circle-o" style={{ fontSize: 25 }}></i>
        } */}
      </div>

      <div className={Data == option ? "active-option2" : "passive-option2"}>
        <span className="answer-text" >
          {DataValue}
        </span>
      </div>
    </div>
  )
}

export default function ThridScreenComponent(props) {
  const {
    oneQuestionData,
    AnswerArray, ArrayIndex,
    setQuestionsSolved, QuestionsSolved,
    setTotalCorrect, TimerValue
  } = props;
  const [AnswerSelected, setAnswerSelected] = React.useState("")
  const [ImageSelect, setImageSelect] = React.useState("")
  const [Answer, setAnswer] = React.useState("")
  const [correct, setCorrect] = React.useState("")

  const selectFunction = (number) => {
    setAnswer(Answer + number)
    AnswerArray[ArrayIndex] = {
      image: oneQuestionData.image,
      number: oneQuestionData.number,
      correct_answer: correct,
      answerChoosen: Answer + number
    }
  }
  return (
    <div>
      <div className="row question-block">
        <div className="col-md-6 ">
          <div style={{
            margin: "3px", padding: "3px",
            border: "1px solid #999", overflowY: 'scroll', backgroundColor: "#f3f3f3",
          }}
            className="question-height"
          >
            <div
              style={{ padding: "10px 0 0 0" }}
            >
              <h3 className="question-font" style={{ fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                {ArrayIndex+1}
              </h3>
            </div>
            <div className="question-description">
              <>
                <div className="center"
                  onClick={() => {
                    setImageSelect(oneQuestionData.image)
                  }}
                >
                  <img className='color-vision-image'
                    src={oneQuestionData.image}
                  />
                </div>
              </>

            </div>

          </div>
        </div>
        <div className="col-md-6">
          <div style={{ padding: "10px", border: "1px solid #999", overflowY: 'scroll', backgroundColor: "#f3f3f3", }}
            className="question-height"
          >
            <div className='row'>
              <div className='col-3'>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  <div
                    className='color-vision-title'

                    style={{ backgroundColor: color2, color: "#fff", }}>
                    Total:{AnswerArray.length}
                  </div>
                </div>
              </div>

              <div className='col-3'>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  <div
                    className='color-vision-title'
                    style={{ backgroundColor: color2, color: "#fff" }}>
                    {Answer}&nbsp;
                  </div>
                </div>
              </div>
              <div className='col-3'>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  <div
                    className='color-vision-title'
                    style={{ backgroundColor: color2, color: "#fff" }}>
                    Solved:
                    {AnswerArray.filter(a => a.answerChoosen !== "a").length}
                  </div>
                </div>
              </div>
              <div className='col-3'>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                  <div
                    className='color-vision-title'
                    style={{ backgroundColor: color2, color: "#fff" }}>
                    {TimerValue}&nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div className='row' style={{ padding: "20px" }}>
              <div className='letter' onClick={() => { selectFunction(1) }}>1</div>
              <div className='letter' onClick={() => { selectFunction(2) }}>2</div>
              <div className='letter' onClick={() => { selectFunction(3) }}>3</div>
              <div className='letter' onClick={() => { selectFunction(4) }}>4</div>
              <div className='letter' onClick={() => { selectFunction(5) }}>5</div>
              <div className='letter' onClick={() => { selectFunction(6) }}>6</div>
              <div className='letter' onClick={() => { selectFunction(7) }}>7</div>
              <div className='letter' onClick={() => { selectFunction(8) }}>8</div>
              <div className='letter' onClick={() => { selectFunction(9) }}>9</div>
              <div className='letter' onClick={() => { selectFunction(".") }}>.</div>
              <div className='letter' onClick={() => { selectFunction(0) }}>0</div>
              <div className='letter' onClick={() => {
                var answerC = Answer
                // answerC.substring(0, answerC.length - 1)
                // answerC.slice(0, answerC.length - 1)
                setAnswer(Answer.slice(0, -1))
                AnswerArray[ArrayIndex] = {
                  image: oneQuestionData.image,
                  number: oneQuestionData.number,
                  correct_answer: correct,
                  answerChoosen: Answer.slice(0, -1)
                }
              }}>del</div>
            </div>
          </div>
        </div>
      </div>
      {ImageSelect !== "" &&
        <div className="modal center"
          onClick={() => {
            setImageSelect("")
          }}
        >
          <img className="image-modal"
            src={ImageSelect}
          />

        </div>
      }
    </div>
  )
}