import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';
import { NodePOSTApiAuth } from '../../../Constant/api';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function StartDailyExam(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({});

  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID

    NodePOSTApiAuth({ userID: id, examSlug: slug }, "start-batch-exam-details")
      .then(res => {
        console.log(res.data.error)
        if (res.data.error == true) {
          setNotFound(true)
          console.log(41)
          setExamFetch(true)
        }
        else {
          if (res.data.result_data.length !== 0) {
            setChoose(2)
            console.log(res.data.result_data[0])
            setAnswerArray(JSON.parse(res.data.result_data[0].meta.exam_answers_array_results))
            setResultData(res.data.result_data[0])
          }
          setAllExamList(res.data.exam_data[0])
          setExamFetch(true)
        }
      })
      .finally(() => {
        setReload(false)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })

  }

  const [finishStatus, setfinishStatus] = React.useState(false);
  const handle = useFullScreenHandle();

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    }
    else
      handle.enter()
    fetchExam(location.pathname.slice(slugs.start_daily_exam.length + 1))
  }, [])

  return (
    <div
      style={{ backgroundColor: "#fff" }}
    >
      <FullScreen handle={handle}>

        <div style={{ backgroundColor: "#eee", minHeight: "100vh" }}>
          <div>

            <div className="row">
              <div className=" col-1"></div>
              <div className=" col-10 form-view m-3">
                {ExamFetch &&
                  <>
                    {NotFoundData ?
                      <NotFound /> :
                      <>
                        <FirstScreenExam
                          ExamData={ExamData}
                          setReload={setReload}
                          choose={choose}
                          setChoose={setChoose}
                          AnswerArray={AnswerArray}
                          setAnswerArray={setAnswerArray}
                          ResultData={ResultData} setResultData={setResultData}
                        />
                      </>
                    }
                  </>
                }
              </div>
            </div>
          </div>
          {reload &&
            <div className="modal" style={{ padding: "50vw" }}>
              <div className="dot-pulse"></div>
            </div>
          }
        </div>
      </FullScreen>
    </div>
  )
}